<div class="duration-input-container">
    <div class="input-label-container">
        <div class="label">{{ label }} {{ required && !hideRequiredAsterisk ? '*' : '' }}</div>
        <div class="time-input-container">
            <div class="time-container" [ngClass]="{'disabled': isDisabled }">
                <input
                    matInput
                    type="text"
                    [ngClass]="{ 'transparent-cursor': transparentCursor, 'disabled' : isDisabled }"
                    [ngModel]="getHoursValue()"
                    (keydown)="onHoursKeyDown($event)"
                    (keyup)="onHoursKeyUp($event)"
                    (blur)="onHourInputBlur()"
                    (focus)="onHourInputFocus()"
                    [disabled]="isDisabled"
                    #hourInputRef
                />
            </div>
            <div class="separator">:</div>
            <div class="time-container" [ngClass]="{'disabled': isDisabled }">
                <input
                    matInput
                    type="text"
                    [ngClass]="{ 'transparent-cursor': transparentCursor, 'disabled' : isDisabled }"
                    [ngModel]="getMinutesValue()"
                    (keydown)="onMinutesKeyDown($event)"
                    (keyup)="onMinutesKeyUp($event)"
                    (blur)="onMinuteInputBlur()"
                    (focus)="onMinuteInputFocus()"
                    [disabled]="isDisabled"
                    #minuteInputRef
                />
            </div>
        </div>
    </div>
</div>

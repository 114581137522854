import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { NativeDateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { LanguageService } from '@app/service/language.service';
import { Subscription } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
    private languageSubscription: Subscription;

    constructor(
        private languageService: LanguageService,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(PLATFORM_ID) public platform_id: object,
    ) {
        super(locale, new Platform(platform_id));
        this.setLanguage();

        this.languageSubscription = this.languageService.languageChanged$.subscribe((lang: string) => {
            this.setLanguage();
        });
    }

    getFirstDayOfWeek(): number {
        return 1;
    }

    setLanguage() {
        let lang = this.languageService.getLanguage();
        this.setLocale(lang);
    }
}

export enum Role {
    PROJECT_MANAGER = 'PROJECT_MANAGER',
    HR = 'HR',
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT',
    ENGINEER = 'ENGINEER',
    QA = 'QA',
    EXECUTIVE_ASSISTANT = 'EXECUTIVE_ASSISTANT',
    SALES = 'SALES',
    MARKETING = 'MARKETING',
    IT = 'IT',
    DESIGNER = 'DESIGNER',
    SEM = 'SEM',
}

<div class="dialog">
    <div *ngIf="data.icon" class="w-100 d-flex justify-content-center align-items-center">
        <img [src]="iconURL" height="90px" class="icon"/>
   </div>
    <div class="text">
        <p>{{ data.message | translate }}</p>
    </div>
    <textarea
    class="reason"
    [placeholder]="(data.placeholdertext) ? (data.placeholdertext | translate) : ('Reasoning')"
    (ngModelChange)="onReasoningChange($event)"
    [(ngModel)]="reasoning"
    >
    </textarea>
    <p class="inputLength">{{reasoning.length}}/{{MAX_MESSAGE_LENGTH}}</p>
    <div class="d-flex justify-content-between buttons">
         <app-stroked-blue-button
            [title]="data.no | translate"
            [mat-dialog-close]="true"
            (onButtonClick)="close(false)"
        >
        </app-stroked-blue-button>
        <app-blue-button 
            [title]="data.yes | translate" 
            [disabled] = "!canBeSent(data.isTextOptional)" 
            (onButtonClick)="onSave()"
            [class.disabledButton]="!canBeSent(data.isTextOptional)"
         >
        </app-blue-button>
        
    </div>
</div>

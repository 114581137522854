<div class="dialog fte-dialog">
    <form autocomplete="off" [formGroup]="fteForm">
        <div class="dialog-header">
            <div class="header-block" [class.header-block-margin]="!isAddedWithDrop">
                <div
                class="profile-icon mt-1"
                [style.background-image]="this.coworkerImage"
                >
                </div>
                <div *ngIf="isAddedWithDrop; else coworkerDropdown" class="name drop-name-margin" title="{{ movedCoworker?.name }}">{{ movedCoworker?.name }}</div>
                <ng-template #coworkerDropdown>
                    <app-select
                        [options]="coworkers"
                        class="coworker-dropdown"
                        (valueChange)="setSelectedCoworker($event)"
                        placeholder="project.project-addition.coworker-select-placeholder"
                    >
                    </app-select>
                </ng-template>
            </div>
            <div class="header-block">
                <div
                class="arrow-icon mt-1"
                [style.background-image]="'url(' + rightArrowPath + ')'"
                >
            </div>
            </div>
            
            <div class="header-block">
                <div
                class="profile-icon mt-1"
                [style.background-image]="this.projectImage"
                >
                </div>
                <div 
                    class="name"
                    [ngClass]="{'drop-name-margin': isAddedWithDrop, 'button-name-margin': !isAddedWithDrop}"
                    title="{{ project.name }}"
                >
                    {{ project.name }}
                </div>
            </div>
        </div>
        <div *ngIf="isEngineer()">
            <p class="inputTitle">{{ 'coworkers.addition.fte' | translate }}*</p>
            <app-select [options]="fteValues" formControlName="fte" [disabled]="isFteFieldDisabled"></app-select>
            <div *ngIf="isProjectRemovalNeeded()" class="mt-1">
                <p class="inputTitle">{{ 'coworkers.addition.remove-from' | translate }}*</p>
                <app-select [options]="removeFromProjectOptions" formControlName="removeFrom"></app-select>
            </div>
        </div>
        <div *ngIf="moveWithSelectedFteWouldAffectOtherProjects()" class="project-affect-alert"><small>{{ 'coworkers.addition.project-affect-alert' | translate }}</small></div>
        <div class="checkbox-container" *ngIf="isEngineer()">
            <app-checkbox
              [defaultChecked]="false"
              [label]="'coworkers.addition.reviewer' | translate"
              (checkboxChange)="disableFteFieldByCheckboxValue($event)"
              [value]="isReviewer"
              formControlName="isReviewer"
            ></app-checkbox>
        </div>
    </form>
    <div class="d-flex justify-content-between buttons">
            <app-stroked-blue-button class="btn" [title]="'globalconstant.cancel' | translate" [mat-dialog-close]="false">
            </app-stroked-blue-button>
            <app-blue-button class="btn" [title]="'globalconstant.save' | translate" (onButtonClick)="save()">
            </app-blue-button>
    </div>
</div>

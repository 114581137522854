import { CalendarDayDate } from './calendar-day-date.model';
import { ShiftTimes } from './shift-times.model';
import { ShiftState } from '@app/enum/shift-state.enum';

export class Shift {
    public id: number;
    public userId: number;
    public dates: CalendarDayDate[];
    public startDate: Date;
    public endDate: Date;
    public intervals: ShiftTimes[];
    public state: ShiftState;
    public isSplit: boolean;
}

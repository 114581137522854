import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from './checkbox.component';

@NgModule({
    imports: [CommonModule, TranslateModule, MatInputModule, MatCheckboxModule, MatIconModule, ReactiveFormsModule],
    declarations: [CheckboxComponent],
    exports: [CheckboxComponent],
})
export class CheckboxModule {}

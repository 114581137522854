export class ShiftTimes {
    public id: number;
    public start: string;
    public end: string;
    public previousStart: string;
    public previousEnd: string;
    public hasDayOff: boolean;

    constructor(start: string, end: string) {
        this.start = start;
        this.end = end;
    }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, forwardRef } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormGroup,
    FormGroupDirective,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input() formControlName: string;
    @Input() label: string;
    @Input() defaultChecked = false;
    @Input() value = true;
    @Input() disabled = false;
    @Output() checkboxChange: EventEmitter<any> = new EventEmitter();
    checked = false;
    private touched = false;

    onTouched: any = () => {};
    onChange = (value: Boolean) => {};

    ngOnInit() {
        this.checked = this.defaultChecked;
        this.checked = this.value;
    }

    ngOnChanges() {
        this.checked = this.value;
    }

    valueChanged({ checked }: MatCheckboxChange) {
        this.onChange(checked);
        this.markAsTouched();
        this.checked = checked;
        this.checkboxChange.emit(checked);
    }

    registerOnChange(fn: () => any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => any): void {
        this.onTouched = fn;
    }

    writeValue(checked: boolean): void {
        this.checked = checked;
    }

    private markAsTouched(): void {
        if (!this.touched) {
            this.touched = true;
            this.onTouched();
        }
    }
}

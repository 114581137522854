import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlueButtonComponent } from './blue-button.component';
import { ShiftRequestComponent } from '@app/view/shifts/shifts-request/shifts-request.component';

@NgModule({
    imports: [CommonModule],
    declarations: [BlueButtonComponent],
    providers: [ShiftRequestComponent],
    exports: [BlueButtonComponent],
})
export class BlueButtonModule {}

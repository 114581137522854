import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@app/../environments/environment';
import { TokenStorageService } from './token-storage.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private imageSource = new BehaviorSubject(`${environment.serverUrl}/user/profile/${this.tokenStorage.getUserId()}`);
    currentImage = this.imageSource.asObservable();
    constructor(
        // tslint:disable-next-line:deprecation
        private httpService: HttpService,
        private tokenStorage: TokenStorageService,
    ) {}

    getProfilePic(id: any) {
        return this.httpService.getFile('/user/profile/', id);
    }

    getUsername(id: any): Observable<String[]> {
        return this.httpService.get<String[]>('/user/username/' + id);
    }

    changeProfilePic(content: any) {
        this.imageSource.next(content);
    }
}

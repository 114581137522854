import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { InputComponent } from './input.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveTypedFormsModule } from '@rxweb/reactive-form-validators';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatInputModule,
        MatIconModule,
        ReactiveTypedFormsModule,
        MatOptionModule,
        MatAutocompleteModule,
    ],
    declarations: [InputComponent],
    exports: [InputComponent],
})
export class InputModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedService {
    public flagSource = new BehaviorSubject<boolean>(true);
    flag$ = this.flagSource.asObservable();
    reasoningMessage: string = '';

    setFlag(flag: boolean) {
        this.flagSource.next(flag);
    }

    setReasoningMessage(message: string) {
        this.reasoningMessage = message;
    }
}

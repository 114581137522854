import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NewFolderDialogComponent } from '../dialogs/document-dialogs/new-folder-dialog/new-folder-dialog.component';
import { Folder } from 'src/app/model/folder.model';
import { DocumentService } from 'src/app/service/document.service';
import { TokenStorageService } from 'src/app/service/token-storage.service';
import { SHOWN_IN_PHASE_TWO } from '@app/constants/constants';
import { ADMINISTRATIVE_ROLES } from '@app/constants/constants';
import { SharedService } from '@app/service/shared.service';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
    readonly ADMINISTRATIVE_ROLES = ADMINISTRATIVE_ROLES;
    folders: Folder[] = [];
    backdropClass: string;

    constructor(
        private dialog: MatDialog,
        private documentService: DocumentService,
        public translate: TranslateService,
        private sharedService: SharedService,
        public tokenService: TokenStorageService,
    ) {}

    ngOnInit(): void {
        this.documentService.getAllFolders().subscribe((foldersResp) => (this.folders = foldersResp));
        this.sharedService.flag$.subscribe((flag) => {
            this.backdropClass = flag ? 'dialog-background-large-sidebar' : 'dialog-background-small-sidebar';
        });
    }

    creatrFolder() {
        this.dialog
            .open(NewFolderDialogComponent, {
                backdropClass: this.backdropClass,
                data: {},
                panelClass: 'new-folder-dialog',
            })
            .afterClosed()
            .subscribe(this.refreshFolders.bind(this));
    }
    refreshFolders() {
        this.documentService.getAllFolders().subscribe((foldersResp) => (this.folders = foldersResp));
    }

    protected readonly SHOWN_IN_PHASE_TWO = SHOWN_IN_PHASE_TWO;
}

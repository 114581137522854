import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        this.registerMatIcons();
    }

    registerMatIcons() {
        this.matIconRegistry.addSvgIcon(
            'edit-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/edit.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'download-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/download.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'export-as-xlsx-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/export_as_xlsx.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'calendar',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/calendar.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'info',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/info.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'keyboard_arrow_left',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/keyboard_arrow_left.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'keyboard_arrow_right',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/keyboard_arrow_right.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'grey-arrow-down-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrows/grey_arrow_down.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'grey-arrow-up-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrows/grey_arrow_up.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'arrow-left',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrows/grey_arrow_left.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'arrow-right',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrows/grey_arrow_right.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'check-mark-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/check-mark.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'delete-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/delete.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'plus-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/plus.svg'),
        );
    }
}

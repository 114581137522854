export enum ShiftState {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    REQUESTED_DELETE = 'REQUESTED_DELETE',
    TO_BE_EDITED = 'TO_BE_EDITED',
    APPROVED_DELETE = 'APPROVED_DELETE',
    CHANGE_REQUESTED = 'CHANGE_REQUESTED',
    HIDDEN = 'HIDDEN',
    APPROVED_DAY_OFF = 'APPROVED_DAY_OFF',
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    rabitLogo: boolean = false;
    private rabitLogoSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    hideProgressBar: boolean = false;
    private progressBarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {}

    setLoading(loading: boolean) {
        this.loadingSubject.next(loading);
    }

    getLoading(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    setToRabitLogo() {
        this.rabitLogo = true;
    }

    setHide(loading: boolean) {
        this.progressBarSubject.next(loading);
    }

    getHide(): Observable<boolean> {
        return this.progressBarSubject.asObservable();
    }

    setLogo(loading: boolean) {
        this.rabitLogoSubject.next(loading);
    }

    getLogo(): Observable<boolean> {
        return this.rabitLogoSubject.asObservable();
    }
}

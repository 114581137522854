import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { httpStatusCode } from '../enum/http-status-codes';

/**
 * @deprecated Please don't use this service. I think the original purpose of this service was to handle error responses,
 * but an interceptor already has been created for it. Furthermore this class just wrap the methods of HttpClient.
 */
@Injectable({
    providedIn: 'root',
})
export class HttpService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private tokenStorage: TokenStorageService,
        private snackBar: MatSnackBar,
        public translate: TranslateService,
    ) {}

    public get<T>(endpoint: string): Observable<T> {
        return this.httpClient.get<T>(`${environment.serverUrl}${endpoint}`);
    }

    public postFiles<T>(endpoint: string, files: File[], id: number): Observable<T> {
        const formData: FormData = new FormData();
        files.forEach((file) => {
            formData.append('file', file);
            formData.append('id', JSON.stringify(id));
        });
        return this.httpClient.post<T>(`${environment.serverUrl}${endpoint}`, formData);
    }

    public postFile<T>(endpoint: string, file: File, id: number): Observable<T> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('id', JSON.stringify(id));
        return this.httpClient.post<T>(`${environment.serverUrl}${endpoint}`, formData);
    }

    // TODO: Please make priority to refactor these methods because they are making type-hinting unavailable.
    // Currently this implementation restricts the caller to have the same response type as the body of the request.
    public post<T>(endpoint: string, body: T, addHeaders = true): Observable<T> {
        if (addHeaders) {
            return this.httpClient.post<T>(`${environment.serverUrl}${endpoint}`, body, this.httpOptions);
        } else {
            return this.httpClient.post<T>(`${environment.serverUrl}${endpoint}`, body);
        }
    }

    public patch<T>(endpoint: string, body: T): Observable<T> {
        return this.httpClient.patch<T>(`${environment.serverUrl}${endpoint}`, body, this.httpOptions);
    }

    public delete(endpoint: string) {
        return this.httpClient.delete(`${environment.serverUrl}${endpoint}`);
    }

    public getFile(endpoint: string, id: number = 0) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream',
                Accept: 'application/octet-stream',
                observe: 'response',
            }),
        };
        const url = id ? `${environment.serverUrl}${endpoint}` + id : `${environment.serverUrl}${endpoint}`;
        return this.httpClient.get(url, {
            headers: httpOptions.headers,
            responseType: 'blob',
        });
    }

    private errorHandler<T>(response: HttpErrorResponse) {
        let errorTitle: string;
        if (
            response.status === httpStatusCode.BAD_REQUEST ||
            response.status === httpStatusCode.INTERNAL_SERVER_ERROR
        ) {
            const errKey = response.error.message;
            if (this.translate.instant('error.' + errKey) !== 'error.' + errKey) {
                errorTitle = this.translate.instant('error.' + errKey);
            } else errorTitle = this.translate.instant('error.500');
        }

        if (response.status === httpStatusCode.UNAUTHORIZED) {
            this.tokenStorage.clearStorage();
            this.router.navigate(['/login']);
            errorTitle = this.translate.instant(`error.${response.error.message}`);
        }

        this.snackBar.open(errorTitle, this.translate.instant('error.close'), {
            duration: 6000,
        });
        return EMPTY;
    }
}

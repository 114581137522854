import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@app/service/loader.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { NavigationStart } from '@angular/router';

export let browserRefresh = false;

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private totalRequests = 0;
    private timesheetRequestCounter = 0;
    private subscription: Subscription;

    constructor(
        private loaderService: LoaderService,
        private router: Router,
    ) {
        this.subscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                browserRefresh = true;
            } else if (event instanceof Scroll && event.routerEvent instanceof NavigationEnd) {
                browserRefresh = false;
            }
        });
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.totalRequests++;

        if (request.urlWithParams.includes('timesheet?')) {
            this.timesheetRequestCounter++;

            if (this.timesheetRequestCounter === 1) {
                this.loaderService.setLogo(true);
            }
        } else if (
            !this.isTimeSheetTabChange(request.urlWithParams) &&
            !request.urlWithParams.includes('assets') &&
            !request.urlWithParams.includes('notification')
        ) {
            this.timesheetRequestCounter = 0;
            this.loaderService.setLogo(false);
        }

        this.isSearchTerm(request.urlWithParams) ||
        this.isTimesheetArrow(request.urlWithParams) ||
        this.timesheetRequestCounter > 1 ||
        this.isTimeSheetTabChange(request.urlWithParams) ||
        (this.isPendingReload(request.urlWithParams) && !browserRefresh) ||
        this.isWorklogSearch(request.urlWithParams) ||
        this.isApprovedShiftTabChange(request.urlWithParams) ||
        (this.isLastApproved(request.urlWithParams) && !browserRefresh)
            ? this.loaderService.setHide(true)
            : this.loaderService.setHide(false);
        this.loaderService.setLoading(true);

        return next.handle(request).pipe(
            finalize(() => {
                this.totalRequests--;

                if (this.totalRequests == 0) {
                    this.loaderService.rabitLogo = false;
                    this.loaderService.setLoading(false);
                    this.loaderService.setHide(false);
                }
            }),
        );
    }

    isPendingReload(url: string): boolean {
        return (
            url.includes('/pending') ||
            url.includes('/holidays/') ||
            (url.includes('/coworker/') && !url.includes('coworker-by-activated-pageable'))
        );
    }

    isLastApproved(url: string): boolean {
        return (
            url.includes('/shifts/last-approved') ||
            (url.includes('/coworker/') && !url.includes('coworker-by-activated-pageable'))
        );
    }

    isApprovedShiftTabChange(url: string): boolean {
        return (
            url.includes('shifts/approved-in-range') ||
            (url.includes('coworker/') && !url.includes('coworker-by-activated-pageable'))
        );
    }

    isTimeSheetTabChange(url: string): boolean {
        return url.includes('reports?');
    }

    isSearchTerm(url: string): boolean {
        return url.includes('search?');
    }

    isTimesheetArrow(url: string): boolean {
        return url.includes('worklogs/data?');
    }

    isWorklogSearch(url: string): boolean {
        return url.includes('worklogs/search');
    }
}

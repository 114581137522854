<div class="folders" *ngIf="SHOWN_IN_PHASE_TWO">
    <div class="container-fluid">
        <div class="row d-flex flex-row-reverse">
            <div class="col-12">
                <div class="new-folder float-right" (click)="creatrFolder()" *ngIf="this.tokenService.hasRole(ADMINISTRATIVE_ROLES)">
                    <mat-icon class="new-folder-icon">create_new_folder</mat-icon>
                    <div class="new-folder-text">{{ 'documents.new-folder' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let folder of folders" class="folder col-xl-2 col-lg-4 col-md-6 col-sm-12">
                <folder (refreshFolders)="refreshFolders()" [folder]="folder" class="folder"></folder>
            </div>
        </div>
    </div>
</div>

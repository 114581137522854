<div class="select-wrapper">
    <mat-form-field hideRequiredMarker [class.disabled]="disabled" [ngClass]="{ 'greyed-out': disabled }">
        <mat-label class="label">{{ label | translate }}</mat-label>
        <mat-select
            disableOptionCentering
            panelClass="language-select-panel"
            required="{{ required }}"
            [disabled]="disabled"
            [(value)]="selectedOption"
            (selectionChange)="onSelectChange($event)"
            [placeholder]="!disabled ? (!hiddenSelectOption ? '' : (placeholder | translate)) : ''"
        >
            <mat-option *ngFor="let option of options" [value]="option.id">
                {{ option.name }}
            </mat-option>
        </mat-select>
        <mat-error class="error" *ngIf="hasError">{{ errorMessage }}</mat-error>
    </mat-form-field>
</div>

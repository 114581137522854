import {
    Component,
    forwardRef,
    Input,
    Output,
    ViewChild,
    ElementRef,
    OnChanges,
    SimpleChanges,
    EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { convertDurationStringToMin, DURATION_FORMAT_REGEX } from '@converter/duration-converter';
import { SnackbarService } from '@app/service/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-duration-input',
    templateUrl: 'duration-input.component.html',
    styleUrls: ['duration-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DurationInputComponent),
            multi: true,
        },
    ],
})
export class DurationInputComponent implements ControlValueAccessor, OnChanges {
    @Input() formControlName: string;
    @Input() label: string;
    @Input() required = false;
    @Input() hideRequiredAsterisk = false;
    @Input() errorMessage = '';
    @Input() hasError = false;
    @Input() default?: string = '00:00';
    @Input() maxHours: number = 12;
    @Input() maxMinutes: number = 59;
    @Input() overWriteTime: string;
    @Input() isDisabled: boolean = false;
    @Output() valueChanged = new EventEmitter<string>();
    @Output() shiftDateFail: boolean = false;

    value: string = this.default;
    transparentCursor: boolean = false;
    private propagateChange: (_: any) => void;
    private hours: string = '00';
    private minutes: string = '00';
    private cursorPosition: number = null;
    private readonly colonIndex: number = 2;

    @ViewChild('hourInputRef', { static: true }) hourInputRef: ElementRef<HTMLInputElement>;
    @ViewChild('minuteInputRef', { static: true }) minuteInputRef: ElementRef<HTMLInputElement>;

    constructor(
        private snackbarService: SnackbarService,
        private translate: TranslateService,
    ) {}

    writeValue(value: any) {
        if (value && value.match(DURATION_FORMAT_REGEX)) {
            const [hourStr, minuteStr] = value.split(':');
            this.hours = hourStr.padStart(this.colonIndex, '0') ?? '00';
            this.minutes = minuteStr.padStart(this.colonIndex, '0') ?? '00';
        } else {
            this.hours = '00';
            this.minutes = '00';
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
        // TODO implement on touched function
    }

    errorMessages = {
        startShiftTime: this.translate.instant('timesheet.worklog.time-out-of-range'),
    };

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.hasError?.currentValue) {
            this.snackbarService.openSnackbarWithMessage(this.errorMessage);
        }

        const overWriteTimeChange = changes['overWriteTime'];

        if (
            overWriteTimeChange?.currentValue &&
            overWriteTimeChange?.currentValue !== overWriteTimeChange?.previousValue
        ) {
            const [hours, minutes] = this.overWriteTime.split(':');
            this.hours = hours;
            this.minutes = minutes;
            this.valueChanged.emit(`${this.hours}:${this.minutes}`);
        }
    }

    onHoursKeyDown(event: KeyboardEvent) {
        this.handleNumericInput(event, 'hours');
        this.handleNavigationKeys(event, true);
    }

    onMinutesKeyDown(event: KeyboardEvent) {
        this.handleNumericInput(event, 'minutes');
        this.handleNavigationKeys(event);
    }

    onHoursKeyUp(event: KeyboardEvent) {
        if (event.key === 'Shift' || event.key === 'Tab') return;

        this.transparentCursor = false;

        if (this.cursorPosition !== null) {
            (event.currentTarget as HTMLInputElement).setSelectionRange(
                this.cursorPosition,
                this.cursorPosition,
                'forward',
            );
        }

        if (this.hours.length === this.colonIndex && this.cursorPosition === this.colonIndex) {
            this.minuteInputRef.nativeElement.focus();
            this.minuteInputRef.nativeElement.setSelectionRange(0, 0);
            this.cursorPosition = 0;
        }
    }

    onMinutesKeyUp(event: KeyboardEvent) {
        if (event.key === 'Shift' || event.key === 'Tab') return;

        this.transparentCursor = false;

        if (this.cursorPosition !== null && this.cursorPosition < this.colonIndex) {
            (event.currentTarget as HTMLInputElement).setSelectionRange(
                this.cursorPosition,
                this.cursorPosition,
                'forward',
            );
        }
    }

    getHoursValue(): string {
        return this.hours.padStart(this.colonIndex, '0');
    }

    getMinutesValue(): string {
        return this.minutes.padStart(this.colonIndex, '0');
    }

    onHourInputBlur() {
        if (this.hours === '') {
            this.hours = '00';
            this.hourInputRef.nativeElement.value = this.hours;
        }

        if (parseInt(this.hours) <= this.maxHours) {
            this.shiftDateFail = false;
            this.hours = this.getHoursValue();
            this.valueChanged.emit(`${this.getPaddedDuration()}`);
        } else {
            this.snackbarService.openSnackbarWithMessage('timesheet.worklog.time-out-of-range');
            this.shiftDateFail = true;
        }
    }

    onHourInputFocus() {
        if (this.hours === '00') {
            this.hours = '';
            this.hourInputRef.nativeElement.value = this.hours;
        }
    }

    onMinuteInputBlur() {
        if (this.minutes === '') {
            this.minutes = '00';
            this.minuteInputRef.nativeElement.value = this.minutes;
        }

        if (parseInt(this.minutes) <= this.maxMinutes) {
            this.minutes = this.getMinutesValue();
            this.valueChanged.emit(`${this.getPaddedDuration()}`);
        } else {
            this.snackbarService.openSnackbarWithMessage('timesheet.worklog.time-out-of-range');
        }
    }

    onMinuteInputFocus() {
        if (this.minutes === '00') {
            this.minutes = '';
            this.minuteInputRef.nativeElement.value = this.minutes;
        }
    }

    private move(isForwardMove: boolean = true, unit: 'hours' | 'minutes') {
        if (isForwardMove && this.cursorPosition < this.colonIndex) {
            if (unit === 'hours' || this.cursorPosition < 1) {
                this.cursorPosition++;
            }
        } else if (unit === 'minutes') {
            if (this.cursorPosition === 0 || this.cursorPosition === null) {
                this.hourInputRef.nativeElement.focus();
                this.cursorPosition = 1;
            } else if (this.cursorPosition > 0) {
                this.cursorPosition--;
            }
        } else if (unit === 'hours' && this.cursorPosition > 0) {
            this.cursorPosition--;
        }
    }

    private handleNavigationKeys(event: KeyboardEvent, isHourInput: boolean = false) {
        switch (event.key) {
        case 'ArrowLeft':
            this.move(false, isHourInput ? 'hours' : 'minutes');
            break;
        case 'ArrowRight':
            this.move(true, isHourInput ? 'hours' : 'minutes');
            break;
        }
    }

    private handleNumericInput(event: KeyboardEvent, unit: 'hours' | 'minutes') {
        if (!isNaN(Number(event.key))) {
            const possibleValue = this.getPossibleValue(event, unit);

            const possibleValueInMin =
                unit === 'hours'
                    ? convertDurationStringToMin(`${possibleValue}:${this.minutes}`)
                    : convertDurationStringToMin(`${this.hours}:${possibleValue}`);

            if (!isNaN(possibleValueInMin)) {
                if (unit === 'hours') {
                    this.hours = possibleValue;
                    this.hourInputRef.nativeElement.value = this.hours;
                } else {
                    this.minutes = possibleValue;
                    this.minuteInputRef.nativeElement.value = this.minutes;
                }

                this.cursorPosition = this.getSelectionStart(event) + 1;

                if (this.propagateChange) {
                    this.propagateChange(`${this.getPaddedDuration()}`);
                }
            }
        }

        if (event.key === 'Backspace') {
            if (unit === 'hours') {
                this.hours = this.removeLastCharacter(this.hours);
                this.hourInputRef.nativeElement.value = this.hours;
            } else {
                this.minutes = this.removeLastCharacter(this.minutes);
                this.minuteInputRef.nativeElement.value = this.minutes;
            }

            if (this.propagateChange) {
                this.propagateChange(`${this.getPaddedDuration()}`);
            }
        }

        if (event.key != 'Tab') {
            event.preventDefault();
        }

        this.transparentCursor = true;
    }

    private getPossibleValue(event: KeyboardEvent, unit: 'hours' | 'minutes'): string {
        const selectionStart = this.getSelectionStart(event);
        const currentValue = unit === 'hours' ? this.hours : this.minutes;

        return (
            currentValue.substring(0, selectionStart) +
            event.key +
            currentValue.substring(selectionStart + 1, currentValue.length)
        );
    }

    private getSelectionStart(event: KeyboardEvent): number {
        const selectionStart = (event.currentTarget as HTMLInputElement).selectionStart ?? 0;

        return selectionStart === this.colonIndex ? selectionStart + 1 : selectionStart;
    }

    private getPaddedDuration() {
        return `${this.getHoursValue()}:${this.getMinutesValue()}`;
    }

    private removeLastCharacter(number: string) {
        if (number.length === 2) {
            return number.slice(0, -1);
        } else {
            this.cursorPosition = 0;
            return '0';
        }
    }
}

import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { environment } from '@env/environment';
import CoworkerData from '@model/coworker-data';
import { HttpService } from './http.service';
import { Coworker } from '../model/coworker.model';
import { CoworkerMissingWorklog } from 'src/app/model/coworker-missing-worklog';
import { WorklogTimeInterval } from '../enum/worklog-time-interval.enum';
import { TimesheetCoworker } from '../model/timesheet-coworker.model';
import { FileService } from './file-service';
import Pageable from '@app/model/pageable';
import { Role } from '@app/enum/role.enum';
import { UpdateProjectRequest } from '@app/model/update-project-request.model';
import { Sort } from '@app/enum/sort.enum';
import { MANAGEMENT_ROLES } from '@app/constants/constants';
import { FteValue } from '@app/enum/fte-value.enum';
import { ColorUsage } from '@app/model/coworker-color.model';
import { CoworkerMenuLabel } from '@app/model/coworker-menu-label.model';
import { ProjectType } from '@app/enum/project-type.enum';
import { CoworkerToProjectAdditon } from '@app/model/coworker-to-project-addition.model';

const INIT_COWORKERS_NUMBER = 20;
@Injectable({
    providedIn: 'root',
})
export class CoworkerService {
    @Output() coworkerDelete = new EventEmitter<number>();

    readonly coworkersPath = `${environment.serverUrl}/coworker`;

    constructor(
        // tslint:disable-next-line:deprecation
        private http: HttpClient,
        private httpService: HttpService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private router: Router,
        private fileService: FileService,
    ) {}

    getCoworkersReport(
        from: string,
        to: string,
        filter?: string,
        isQA?: boolean,
        sortOrder?: string,
    ): Observable<CoworkerData[]> {
        let params = new HttpParams();
        params = params.set('from', from);
        params = params.set('to', to);
        params = params.set('sortOrder', sortOrder ?? Sort.ASCENDING);

        if (isQA !== undefined) {
            params = params.set('isQA', isQA.toString());
        }

        return this.http.get<CoworkerData[]>(`${this.coworkersPath}/reports`, { params }).pipe(
            map((coworkers) => {
                if (!filter || filter.length === 0) {
                    return coworkers;
                }

                return coworkers.filter(
                    (coworker) =>
                        coworker.name.toLowerCase().includes(filter.toLowerCase()) ||
                        coworker.projects.some((project) =>
                            project.name.toLowerCase().includes(filter.toLowerCase()),
                        ) ||
                        this.containsTaskName(coworker, filter),
                );
            }),
        );
    }

    private containsTaskName(coworker: CoworkerData, filter: string) {
        const worklogs = coworker.projects.flatMap((project) => project.worklogs);

        return worklogs.some((worklog) => {
            const containsTask = worklog.task.toLowerCase().includes(filter.toLowerCase());

            if (containsTask) {
                for (const project of coworker.projects) {
                    const containsWorklog = project.worklogs.some((log) => log.id === worklog.id);

                    if (containsWorklog) {
                        project.shouldBeOpened = true;
                    }
                }
            }

            return containsTask;
        });
    }

    getQaWorklogInfoForCoworkerTab(): Observable<CoworkerData[]> {
        return this.httpService.get('/coworker/worklog-info-for-qa');
    }

    getCoworkersWithMissingWorklogs(): Observable<CoworkerMissingWorklog[]> {
        return this.httpService.get('/coworker/worklog');
    }

    getCoworkersWithProjects(timeInterval: WorklogTimeInterval): Observable<TimesheetCoworker[]> {
        return this.httpService.get('/coworker/coworkers-with-projects?timeInterval=' + timeInterval);
    }

    getCoworkers(): Observable<Coworker[]> {
        return this.httpService.get('/coworker');
    }

    getCoworkersByActivatedPage(
        active: boolean,
        page: number,
        role: Role,
        pageSize: number = INIT_COWORKERS_NUMBER,
    ): Observable<Pageable<Coworker>> {
        return this.httpService.get(
            '/coworker/coworker-by-activated-pageable?activated=' +
                active +
                '&page=' +
                page +
                '&pageSize=' +
                pageSize +
                '&role=' +
                role,
        );
    }

    getCoworkersBySearchTerm(activated: boolean, searchTerm: string): Observable<Coworker[]> {
        return this.httpService.get('/coworker/search?activated=' + activated + '&searchTerm=' + searchTerm);
    }

    getFreeCoworkersByRoles(roles: string[]): Observable<Coworker[]> {
        return this.http.get<Coworker[]>(`${this.coworkersPath}/free-coworkers`, { params: { roles } });
    }

    inviteUser(coworker: Coworker) {
        return this.httpService.post('/user/invite', coworker);
    }

    saveCoworker(coworker: Coworker): Observable<Coworker> {
        return this.httpService.post('/coworker', coworker);
    }

    updateCoworker(coworker: Coworker): Observable<Coworker> {
        return this.httpService.patch('/coworker/' + coworker.id, coworker);
    }

    saveContracts(contracts: File[], id: number) {
        if (contracts.length > 0) {
            return this.httpService.postFiles('/files/contract', contracts, id).toPromise();
        }
    }

    saveDocuments(documents: File[], id: number) {
        if (documents.length > 0) {
            return this.httpService.postFiles('/files/document', documents, id).toPromise();
        }
    }

    async saveProfilePic(profilePic: File, id: number) {
        if (profilePic) {
            return this.httpService.postFile('/files/profile', profilePic, id).toPromise();
        }
    }

    saveStatements(statements: File[], id: number) {
        if (statements.length > 0) {
            return this.httpService.postFiles('/files/statement', statements, id).toPromise();
        }
    }

    saveLabourDocuments(labourDocuments: File[], id: number) {
        if (labourDocuments.length > 0) {
            return this.httpService.postFiles('/files/labour', labourDocuments, id).toPromise();
        }
    }

    redirect() {
        this.router.navigate(['/co-workers']);
        this.snackBar.open(
            this.translate.instant('coworkers.addition.success_save'),
            this.translate.instant('error.close'),
            {
                duration: 6000,
            },
        );
    }

    deleteImage(index, src: string[], files: File[]) {
        files.splice(index, 1);
        src.splice(index, 1);
    }

    getCoworkerById(id: number): Observable<Coworker> {
        return this.httpService.get('/coworker/' + id);
    }

    deleteCoworker(id: number) {
        return this.httpService.delete(`/coworker/${id}`);
    }

    deleteDocument(id: number) {
        return this.httpService.delete('/coworker/document/' + id).subscribe();
    }

    downloadDocument(endpoint: string, id: number, filename: string) {
        this.httpService.getFile(endpoint, id).subscribe((resp) => {
            const blob = new Blob([resp], { type: 'application/octet-stream' });
            this.fileService.save(blob, filename);
        });
    }

    getJobTitles(): Observable<string[]> {
        return this.httpService.get('/job-titles');
    }

    getRoles(): Observable<string[]> {
        return this.httpService.get('/roles');
    }

    updateProject(coworkerId: number, oldProjectId: number, newProjectId: number, assignedFte: number) {
        return this.httpService.patch(
            `/coworker/${coworkerId}/projects/${oldProjectId}`,
            new UpdateProjectRequest(newProjectId, assignedFte),
        );
    }

    getProjectAssociationsByCoworker(coworkerId: number) {
        return this.http.get(`${this.coworkersPath}/${coworkerId}/associations`);
    }

    isManagementRole(coworker: Coworker) {
        return MANAGEMENT_ROLES.includes(coworker?.role);
    }

    isReviewer(coworker: Coworker): boolean {
        return coworker.role === Role.ENGINEER && coworker.fte === FteValue.REVIEWER_OR_QA;
    }

    getNumberOfCoworkersByRoles() {
        return this.http.get<CoworkerMenuLabel[]>(`${this.coworkersPath}/number-of-coworkers-by-role`);
    }

    getColors(): Observable<ColorUsage[]> {
        return this.httpService.get('/coworker/used-colors');
    }

    getCoworkersAssignableToProject(
        projectType: ProjectType,
        assignedCoworkerIds: number[],
    ): Observable<CoworkerToProjectAdditon[]> {
        const params = new HttpParams()
            .set('projectType', projectType)
            .set('assignedCoworkerIds', assignedCoworkerIds.join(','));

        return this.http.get<CoworkerToProjectAdditon[]>(`${this.coworkersPath}/assignable-to-project`, { params });
    }
}

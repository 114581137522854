import { Role } from '@app/enum/role.enum';
import { CoworkerMenuLabel } from '@app/model/coworker-menu-label.model';
export const SIMPLE_DATE_FORMAT = 'yyyy.MM.DD.';
export const SIMPLE_DATE_FORMAT_DATE_FNS = 'yyyy.MM.dd';
export const MONTH_FORMAT = 'MMMM';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const HOUR_MINUTE_FORMAT = 'H:mm';
export const HOUR_FORMAT = 'HH';
export const MINUTE_FORMAT = 'mm';
export const DEFAULT_PROFILE_PICTURE_PATH = './assets/default.png';
export const DEFAULT_PROJECT_PICTURE_PATH = './assets/defaultproject.png';
export const RIGHT_ARROW_PATH = './assets/arrow-right.svg';
export const PROJECT_TYPE = 'project';
export const COWORKER_TYPE = 'coworker';
export const DEFAULT_LOCALE = 'en-US';
export const DAY_IN_MILLIS = 1000 * 3600 * 24;
export const SUNDAY = 0;
export const SATURDAY = 6;
export const REVIEWER = 'REVIEWER';
export const COWORKER_COLORS = [
    { role: Role.PROJECT_MANAGER, color: '#75485E' },
    { role: Role.ENGINEER, color: '#084062' },
    { role: Role.QA, color: '#86A5D9' },
    { role: Role.DESIGNER, color: '#64A893' },
    { role: Role.HR, color: '#C09E84' },
    { role: Role.EXECUTIVE_ASSISTANT, color: '#D1B656' },
    { role: Role.MARKETING, color: '#B4A36A' },
    { role: Role.SALES, color: '#BF9367' },
    { role: Role.IT, color: '#E3B179' },
    { role: Role.ADMIN, color: '#627773' },
    { role: REVIEWER, color: '#2B666E' },
    { role: Role.SEM, color: '#51604A' },
];
export const DAYS_OF_WEEK_TRANSLATIONS = [
    'holidays.calendar.days.monday',
    'holidays.calendar.days.tuesday',
    'holidays.calendar.days.wednesday',
    'holidays.calendar.days.thursday',
    'holidays.calendar.days.friday',
    'holidays.calendar.days.saturday',
    'holidays.calendar.days.sunday',
];

export const FULL_DAYS_OF_WEEK_TRANSLATIONS = [
    'globalconstant.calendar-days.monday',
    'globalconstant.calendar-days.tuesday',
    'globalconstant.calendar-days.wednesday',
    'globalconstant.calendar-days.thursday',
    'globalconstant.calendar-days.friday',
    'globalconstant.calendar-days.saturday',
    'globalconstant.calendar-days.sunday',
];

/*
  Some parts of the UI need to be hidden in phase one.
  This boolean is used in *ngIf directives on those
  components to conditionally show/hide them as required.
*/
export const SHOWN_IN_PHASE_ONE = false;

/*
  Some parts of the UI need to be hidden in phase two.
  This boolean is used in *ngIf directives on those
  components to conditionally show/hide them as required.
*/
export const SHOWN_IN_PHASE_TWO = false;

export const RoleTranslationKeys = {
    PROJECT_MANAGER: 'roles.project-manager',
    HR: 'roles.hr',
    ADMIN: 'roles.admin',
    CLIENT: 'roles.client',
    ENGINEER: 'roles.engineer',
    QA: 'roles.qa',
    EXECUTIVE_ASSISTANT: 'roles.executive-assistant',
    SALES: 'roles.sales',
    MARKETING: 'roles.marketing',
    IT: 'roles.it',
    DESIGNER: 'roles.designer',
    SEM: 'roles.sem',
};

export const ProfileRolesTranslationKeys = {
    PROJECT_MANAGER: 'profile-roles.project-manager',
    HR: 'profile-roles.hr',
    ADMIN: 'profile-roles.admin',
    CLIENT: 'profile-roles.client',
    ENGINEER: 'profile-roles.engineer',
    QA: 'profile-roles.qa',
    EXECUTIVE_ASSISTANT: 'profile-roles.executive-assistant',
    SALES: 'profile-roles.sales',
    MARKETING: 'profile-roles.marketing',
    IT: 'profile-roles.it',
    DESIGNER: 'profile-roles.designer',
    SEM: 'profile-roles.sem',
};

export const JobTitleTranslationKeys = {
    DEVELOPER: 'job-titles.developer',
    TESTER: 'job-titles.tester',
    PROJECT_MANAGER: 'job-titles.project-manager',
    HR: 'job-titles.hr',
    QA: 'job-titles.qa',
    EXECUTIVE_ASSISTANT: 'job-titles.executive-assistant',
    SALES: 'job-titles.sales',
    MARKETING: 'job-titles.marketing',
    IT: 'job-titles.it',
    DESIGNER: 'job-titles.designer',
    ADMIN: 'job-titles.admin',
    SEM: 'job-titles.sem',
};

export const ExtraHolidayTypeTranslationKeys = {
    COIN: 'holidays.holidays-from-coin-app',
    PATERNITY: 'holidays.paternity-leave',
    OTHER: 'holidays.other-holiday',
};

export const PaymentPeriodTranslationKeys = {
    MONTHLY: 'payment-period.monthly',
    PROJECT: 'payment-period.project',
    SPRINT: 'payment-period.sprint',
};

export const LanguageTranslationKeys = {
    HUNGARIAN: 'languages.hungarian',
    ENGLISH: 'languages.english',
    GERMAN: 'languages.german',
};

export const ProjectTypeTranslationKeys = {
    CLIENT: 'project-types.client',
    MANAGEMENT: 'project-types.management',
};

export const CANCEL_DIALOG_RESULT = 'cancel';

export const ADMINISTRATIVE_ROLES = [Role.ADMIN, Role.EXECUTIVE_ASSISTANT];

export const ADMINISTRATIVE_PM_ROLES = [Role.ADMIN, Role.EXECUTIVE_ASSISTANT, Role.PROJECT_MANAGER];

export const ADMINISTRATIVE_PM_HR_ROLES = [Role.ADMIN, Role.EXECUTIVE_ASSISTANT, Role.PROJECT_MANAGER, Role.HR];
/**
 * Makes the SEM coworkers get access to the Timesheet page.
 */
export const ADMINISTRATIVE_ROLES_TO_THE_TIMESHEET_PAGE = [
    Role.ADMIN,
    Role.EXECUTIVE_ASSISTANT,
    Role.PROJECT_MANAGER,
    Role.HR,
    Role.SEM,
];

export const COWORKER_TAB_ACCESS_ROLES = [Role.ADMIN, Role.EXECUTIVE_ASSISTANT, Role.PROJECT_MANAGER, Role.HR, Role.QA];

export const ADMINISTRATIVE_PM_ENGINEER_ROLES = [
    Role.ADMIN,
    Role.EXECUTIVE_ASSISTANT,
    Role.PROJECT_MANAGER,
    Role.ENGINEER,
];

export const ROLES_WITH_TIMESHEET = [
    Role.ADMIN,
    Role.EXECUTIVE_ASSISTANT,
    Role.PROJECT_MANAGER,
    Role.ENGINEER,
    Role.HR,
    Role.SEM,
];

export const ROLES_WITH_WORKLOG = [
    Role.ENGINEER,
    Role.EXECUTIVE_ASSISTANT,
    Role.HR,
    Role.QA,
    Role.MARKETING,
    Role.IT,
    Role.SALES,
    Role.DESIGNER,
    Role.SEM,
];

export const MANAGEMENT_ROLES = [Role.EXECUTIVE_ASSISTANT, Role.SALES, Role.MARKETING, Role.HR];

export const ROLES_TO_DISPLAY_WITHOUT_FTE = [
    Role.EXECUTIVE_ASSISTANT,
    Role.SALES,
    Role.MARKETING,
    Role.IT,
    Role.HR,
    Role.DESIGNER,
    Role.PROJECT_MANAGER,
    Role.QA,
    Role.SEM,
];

export const COWORKER_MENU_LABELS: CoworkerMenuLabel[] = [
    { role: Role.ENGINEER },
    { role: Role.QA },
    { role: Role.PROJECT_MANAGER },
    { role: Role.DESIGNER },
    { role: Role.SEM },
    { role: Role.EXECUTIVE_ASSISTANT },
    { role: Role.HR },
    { role: Role.MARKETING },
    { role: Role.SALES },
    { role: Role.IT },
    { role: Role.ADMIN },
];

export const COWORKER_TAB_ROLE_ACCESS_MAP = {
    [Role.ENGINEER]: ADMINISTRATIVE_PM_HR_ROLES,
    [Role.QA]: COWORKER_TAB_ACCESS_ROLES,
    [Role.PROJECT_MANAGER]: ADMINISTRATIVE_PM_HR_ROLES,
    [Role.DESIGNER]: ADMINISTRATIVE_PM_HR_ROLES,
    [Role.SEM]: ADMINISTRATIVE_PM_HR_ROLES,
    [Role.EXECUTIVE_ASSISTANT]: ADMINISTRATIVE_PM_HR_ROLES,
    [Role.HR]: ADMINISTRATIVE_PM_HR_ROLES,
    [Role.MARKETING]: ADMINISTRATIVE_PM_HR_ROLES,
    [Role.SALES]: ADMINISTRATIVE_PM_HR_ROLES,
    [Role.IT]: ADMINISTRATIVE_PM_HR_ROLES,
    [Role.ADMIN]: ADMINISTRATIVE_PM_HR_ROLES,
};

export const DEFAULT_SELECT_OPTION = 'default';

export const COWORKER_MAX_ASSIGNED_NON_ZERO_FTE_PROJECT = 2;

export const RGB_MAX_VALUE = 255;

export const ILLEGAL_CHAR_REGEX = /[A-Za-zÀ-ÖØ-öø-ÿŰűŐő\- ]+/g;

<div
    class="request"
    [class.resized]="allShiftsFilled()"
    *ngIf="selectedDays?.length > 0 || shifts?.length > 0 || editMode"
>
    <header>{{ 'shifts.shift-request' | translate }}</header>
    <div class="shifts-container" [class.enlarged-container]="moreThanOneShift">
        <div *ngFor="let shift of shifts; let i = index">
            <div (click)="changeEditedShift(shift)" class="shift">
                <div class="top-container">
                    <div [ngClass]="shift?.dates?.length > 4 ? 'date-holder-big' : 'date-holder'">
                        <div class="dateText" *ngFor="let date of shift.dates">
                            {{ formatDate(date.date) }}
                        </div>
                    </div>

                    <div class="interval-container">
                        <div class="intervals" (click)="changeEditedShift(shift)">
                            <div class="interval-selector">
                                <app-duration-input
                                    [overWriteTime]="editedStartTime?.[i]"
                                    [maxHours]="23"
                                    [maxMinutes]="59"
                                    (valueChanged)="setFirstInterval($event, true)"
                                    #first
                                    [hideRequiredAsterisk]="true"
                                ></app-duration-input>
                            </div>
    
                            <div class="separator">-</div>
    
                            <div class="interval-selector">
                                <app-duration-input
                                    [overWriteTime]="editedEndTime?.[i]"
                                    [maxHours]="23"
                                    [maxMinutes]="59"
                                    (valueChanged)="setFirstInterval($event, false)"
                                    #second
                                    [hideRequiredAsterisk]="true"
                                ></app-duration-input>
                            </div>
                        </div>
                        <div (click)="changeEditedShift(shift)" *ngIf="shift.isSplit" class="intervals">
                            <div class="interval-selector">
                                <app-duration-input
                                    [maxHours]="23"
                                    [maxMinutes]="59"
                                    (valueChanged)="setSecondInterval($event, true)"
                                    #first
                                    [hideRequiredAsterisk]="true"
                                ></app-duration-input>
                            </div>
    
                            <div class="separator">-</div>
    
                            <div class="interval-selector">
                                <app-duration-input
                                    [maxHours]="23"
                                    [maxMinutes]="59"
                                    (valueChanged)="setSecondInterval($event, false)"
                                    #second
                                    [hideRequiredAsterisk]="true"
                                ></app-duration-input>
                            </div>
                            
                        </div>
                    </div>

                    <div class="split-button-container">
                        <button
                        [disabled]="canBeSplit() || !isCurrentShift(shift)"
                        matTooltip="{{ 'shifts.split-shift-button' | translate }}"
                        [matTooltipPosition]="'below'"
                        [matTooltipClass]="'below'"
                        *ngIf="!shift.isSplit"
                        class="split-shift-button"
                        (click)="splitShift(shift)"
                        >
                        <mat-icon class="plus">add_circle_outline</mat-icon>
                    </button>
                    <button
                        matTooltip="{{ 'shifts.unsplit-shift-button' | translate }}"
                        [matTooltipPosition]="'below'"
                        [matTooltipClass]="'below'"
                        *ngIf="shift.isSplit"
                        class="split-shift-button"
                        (click)="splitShift(shift)"
                    >
                        <mat-icon class="plus">remove_circle_outline</mat-icon>
                    </button>
                    </div>
                </div>

            </div>
            <hr class="line" />
        </div>
    </div>
    <div class="button-container">
        <button
            *ngIf="!editMode"
            matTooltip="{{ 'shifts.new-shift-button' | translate }}"
            [matTooltipPosition]="'left'"
            [matTooltipClass]="'left'"
            class="new-shift-button"
            (click)="addNewShift()"
        >
            <mat-icon class="plus">add_circle_outline</mat-icon>
        </button>
        <app-stroked-blue-button
            *ngIf="!editMode"
            class="cancel-button"
            [title]="'globalconstant.cancel' | translate"
            (onButtonClick)="onCancel()"
        ></app-stroked-blue-button>
        <app-stroked-blue-button
            *ngIf="editMode"
            class="cancel-button"
            [title]="'globalconstant.cancel' | translate"
            (onButtonClick)="onEditCancel()"
        ></app-stroked-blue-button>
        <app-blue-button
            class="save-button"
            [disabled]="!canBeSaved()"
            (onButtonClick)="onSave()"
            [title]="'globalconstant.save' | translate"
        ></app-blue-button>
    </div>
</div>

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '@app/service/shared.service';

@Component({
    selector: 'app-confirmation-dialog-with-message',
    templateUrl: './confirmation-dialog-with-message.component.html',
    styleUrls: ['./confirmation-dialog-with-message.component.scss'],
})
export class ConfirmationDialogWithMessageComponent {
    MAX_MESSAGE_LENGTH = 500;
    reasoning: string = '';
    iconURL = `/assets/icons/${this?.data?.icon}`;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<ConfirmationDialogWithMessageComponent>,
        public sharedService: SharedService,
    ) {}

    close(value: boolean) {
        this.matDialogRef.close({ value, reasoning: this.reasoning });
        this.reasoning = '';
    }

    onReasoningChange($event) {
        this.reasoning = $event;
    }

    onSave() {
        this.close(true);
    }

    canBeSent(isTextOptional: boolean = false): boolean {
        const minLength = this.data.minLength ?? -1;
        
        return isTextOptional || (this.reasoning.length > minLength && this.reasoning.length <= this.MAX_MESSAGE_LENGTH);
    }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { TokenStorageService } from '../service/token-storage.service';
import { AuthenticationService } from '@app/service/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private readonly authHeader = 'Authorization';

    private refreshingInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private tokenService: TokenStorageService,
        private authService: AuthenticationService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addAuthorizationHeader(request)).pipe(
            catchError((error) => {
                if (error.status === 490) {
                    this.authService.signOutUser();
                }
                // in case of 401 http error
                if (error instanceof HttpErrorResponse && [401, 403].includes(error.status)) {
                    if (this.tokenService.getRefreshToken() && this.tokenService.getToken()) {
                        return this.refreshToken(request, next);
                    }
                }

                return throwError(error);
            }),
        );
    }

    private addAuthorizationHeader(request: HttpRequest<any>) {
        if (this.tokenService.getToken()) {
            return request.clone({
                headers: request.headers.set(this.authHeader, `Bearer ${this.tokenService.getToken()}`),
            });
        } else {
            return request;
        }
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.refreshingInProgress) {
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken(this.tokenService.getRefreshToken()).pipe(
                switchMap((res) => {
                    this.refreshingInProgress = true;
                    this.refreshTokenSubject.next(res.accessToken);
                    this.tokenService.saveToken(res.accessToken);
                    const header = this.addAuthorizationHeader(request);
                    return next.handle(header);
                }),
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthorizationHeader(request))),
            );
        }
    }
}

import { HOUR_MINUTE_FORMAT, SIMPLE_DATE_FORMAT_DATE_FNS } from '@app/constants/constants';
import { ShiftDate } from '@app/model/shift-date.model';
import { ShiftState } from '@app/enum/shift-state.enum';
import { format, parseISO } from 'date-fns';
import { FormattedShift } from '@app/model/shift/formatted-shift.model';

export const hasDayOff = (shift: ShiftDate): boolean => {
    return shift.shiftTimes.filter((shiftTime) => shiftTime.hasDayOff).length > 0;
};

/**
 * Return an object containing human readable strings with all dates of shift and with work hours
 * @param shift ShiftDate object
 * @returns FormattedShift object, something like: { periodsString: "2023.12.19 - 2023.12.21", dates:⠀"2023.12.25", timesString: "7:00 - 11:00, 13:00 - 15:00""
 */
export const formatShiftDatesAndTimes = (shift: ShiftDate, isPreviousDates: boolean = false): FormattedShift => {
    const { periods, datesToRemove } = getPeriodsString(shift, isPreviousDates);

    if (isPreviousDates && shift.shiftTimes.filter((date) => date.previousStart && date.previousEnd).length == 0) {
        return null;
    }

    const dates = shift.shiftTimes
        .filter((date) => !datesToRemove.includes(isPreviousDates ? date.previousStart : date.start))
        .reduce(
            (acc, current) =>
                acc + `${formatShiftDate(isPreviousDates ? current.previousStart : current.start)},\u2800`,
            '',
        )
        .slice(0, -2)
        .concat('.');

    const uniqueTimes = new Set();
    shift.shiftTimes.map((time) =>
        uniqueTimes.add(` \t${formatShiftTime(isPreviousDates ? time.previousStart : time.start)}
    - ${formatShiftTime(isPreviousDates ? time.previousEnd : time.end)}`),
    );
    const timesString: string = [...uniqueTimes].map((timeString) => `${timeString}`).toString();

    let periodsString = periods.join(', ');

    return { dates, periodsString, timesString };
};

/**
 * Returns human readable periods from shift and an array of dates which should not be stringified (because is included in a period)
 * @param shift
 */
export const getPeriodsString = (
    shift: ShiftDate,
    isPreviousDates: boolean,
): { periods: string[]; datesToRemove: string[] } => {
    let periods: string[] = [];
    let datesToRemove = new Set<string>();

    let previousDate = isPreviousDates ? shift.shiftTimes[0].previousStart : shift.shiftTimes[0].start;
    let startDate = previousDate;
    let endDate = previousDate;
    const addToPeriods = (start: string, end: string) =>
        periods.push(`${formatShiftDate(start)} - ${formatShiftDate(end)}`);
    const originalStartDate = previousDate;

    const resetDates = () => {
        startDate = null;
        endDate = null;
    };

    for (let i = 1; i < shift.shiftTimes.length; i++) {
        if (shift.shiftTimes[i]) {
            const prev: Date = parseISO(previousDate);
            previousDate = isPreviousDates ? shift.shiftTimes[i].previousStart : shift.shiftTimes[i].start;

            if (prev.getDate() + 1 === parseISO(shift.shiftTimes[i].start).getDate()) {
                endDate = isPreviousDates ? shift.shiftTimes[i].previousStart : shift.shiftTimes[i].start;
                datesToRemove.add(startDate);
                datesToRemove.add(endDate);
            } else if (prev.getDate() >= parseISO(shift.shiftTimes[i].start).getDate()) {
                endDate = isPreviousDates ? shift.shiftTimes[i].previousStart : shift.shiftTimes[i].start;
                datesToRemove.add(endDate);
            } else {
                if (startDate && endDate && parseISO(startDate).getDate() !== parseISO(endDate).getDate()) {
                    addToPeriods(startDate, endDate);
                    resetDates();
                    startDate = isPreviousDates ? shift.shiftTimes[i].previousStart : shift.shiftTimes[i].start;
                } else {
                    startDate = isPreviousDates ? shift.shiftTimes[i].previousStart : shift.shiftTimes[i].start;
                }
            }
        }
    }

    if (startDate && endDate && parseISO(startDate).getDate() !== parseISO(endDate).getDate()) {
        addToPeriods(startDate, endDate);
        resetDates();
    }

    periods = mergeIntervals(periods);

    return { periods, datesToRemove: [...datesToRemove] };
};

export const parseDate = (date: string): Date => {
    const [year, month, day] = date.split('.').map(Number);

    return new Date(year, month - 1, day);
};

export const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}.${month}.${day}`;
};

export const areConsecutiveIntervals = (endDate, startDate): boolean => {
    const daysBetween = (startDate - endDate) / (1000 * 60 * 60 * 24);

    if (daysBetween === 3) {
        return endDate.getDay() === 5 && startDate.getDay() === 1;
    }

    return daysBetween === 1;
};

export const mergeIntervals = (intervals: string[]): string[] => {
    if (!intervals.length) return [];

    const parsedIntervals = intervals.map((interval) => {
        const [startStr, endStr] = interval.split(' - ');
        return [parseDate(startStr), parseDate(endStr)];
    });

    parsedIntervals.sort((a: any, b: any) => a[0] - b[0]);

    const mergedIntervals = [];
    let [currentStart, currentEnd] = parsedIntervals[0];

    for (let i = 1; i < parsedIntervals.length; i++) {
        const [nextStart, nextEnd] = parsedIntervals[i];
        if (areConsecutiveIntervals(currentEnd, nextStart)) {
            currentEnd = nextEnd;
        } else {
            mergedIntervals.push([currentStart, currentEnd]);
            [currentStart, currentEnd] = [nextStart, nextEnd];
        }
    }

    mergedIntervals.push([currentStart, currentEnd]);

    return mergedIntervals.map(([startDate, endDate]) => `${formatDate(startDate)} - ${formatDate(endDate)}`);
};

export const formatShiftDate = (date: string) => format(parseISO(date + 'Z'), SIMPLE_DATE_FORMAT_DATE_FNS);

export const formatShiftTime = (date: string) => format(parseISO(date + 'Z'), HOUR_MINUTE_FORMAT);

export const hasShiftState = (stateToCheck: ShiftState, state: ShiftState): boolean => {
    return stateToCheck === state;
};

import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Project } from '../model/project.model';
import { Coworker } from '../model/coworker.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { CoworkerProject } from '../model/coworker-project.model';
import { ProjectTimesheet } from '@app/model/project-timesheet.model';
import { ProjectFilter } from '@app/model/filter/project.filter.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Pageable from '@app/model/pageable';

const INIT_PROJECTS_NUMBER = 100000;
@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    coworkers: Coworker[] = [];
    projects: Project[];
    addProject: EventEmitter<Project> = new EventEmitter();

    constructor(
        // tslint:disable-next-line:deprecation
        private httpService: HttpService,
        private httpClient: HttpClient,
        public translate: TranslateService,
        private tokenStorage: TokenStorageService,
    ) {}

    getProjectsByActivatedPage(
        active: boolean,
        page: number,
        pageSize: number = INIT_PROJECTS_NUMBER,
    ): Observable<Pageable<Project>> {
        return this.httpService.get(
            '/project/projects-by-activated-pageable?active=' + active + '&page=' + page + '&pageSize=' + pageSize,
        );
    }

    getProjectsForTimesheet(projectFilter: ProjectFilter, filter?: string): Observable<ProjectTimesheet[]> {
        return this.httpClient
            .get<ProjectTimesheet[]>(`${environment.serverUrl}/project/timesheet`, {
                params: projectFilter.getQueryParams(),
            })
            .pipe(
                map((projects) => {
                    let projectsIncludingPaid = [...projects];

                    projects.map((project) => {
                        if (project?.paidQA && project?.sumOfQaWorkHours && project?.sumOfQaWorkHours !== '00:00') {
                            const paidQAProject = {
                                ...project,
                                name: `${project.name} - QA`,
                                sumOfQaWorkHours: project.sumOfQaWorkHours,
                                sumOfWorkHours: null,
                                sumOfPmWorkHours: null,
                                sumOfDesignWorkHours: null,
                            };
                            const index = projectsIncludingPaid.findIndex((p) => p.id === project.id) + 1;
                            projectsIncludingPaid.splice(index, 0, paidQAProject);
                        }

                        if (project.paidPm && project?.sumOfPmWorkHours && project?.sumOfPmWorkHours !== '00:00') {
                            const paidPmProject = {
                                ...project,
                                name: `${project.name} - PM`,
                                sumOfWorkHours: null,
                                sumOfPmWorkHours: project.sumOfPmWorkHours,
                                sumOfQaWorkHours: null,
                                sumOfDesignWorkHours: null,
                            };
                            const index = projectsIncludingPaid.findIndex((p) => p.id === project.id) + 1;
                            projectsIncludingPaid.splice(index, 0, paidPmProject);
                        }

                        if (
                            project.paidDesign &&
                            project?.sumOfDesignWorkHours &&
                            project?.sumOfDesignWorkHours !== '00:00'
                        ) {
                            const paidDesignProject = {
                                ...project,
                                name: `${project.name} - Design`,
                                sumOfWorkHours: null,
                                sumOfDesignWorkHours: project.sumOfDesignWorkHours,
                                sumOfQaWorkHours: null,
                                sumOfPmWorkHours: null,
                            };
                            const index = projectsIncludingPaid.findIndex((p) => p.id === project.id) + 1;
                            projectsIncludingPaid.splice(index, 0, paidDesignProject);
                        }
                    });

                    projectsIncludingPaid = projectsIncludingPaid.filter(
                        (project) =>
                            (project.name.endsWith(' - QA') && project.sumOfQaWorkHours != '00:00') ||
                            (project.name.endsWith(' - PM') && project.sumOfPmWorkHours != '00:00') ||
                            (project.name.endsWith(' - Design') && project.sumOfDesignWorkHours != '00:00') ||
                            (!project.name.endsWith(' - QA') &&
                                !project.name.endsWith(' - PM') &&
                                !project.name.endsWith(' - Design') &&
                                project.sumOfWorkHours != '00:00'),
                    );

                    if (!filter) {
                        return projectsIncludingPaid;
                    }

                    const filteredProjects: ProjectTimesheet[] = [];

                    projectsIncludingPaid.filter(function (project) {
                        if (project.name.toLowerCase().includes(filter.toLowerCase())) {
                            filteredProjects.push(project);
                        }
                    });

                    return filteredProjects;
                }),
            );
    }

    createProject(project: Project): Observable<Project> {
        return this.httpService.post<Project>('/project', project);
    }

    updateProject(project: Project): Observable<Project> {
        return this.httpService.patch<Project>(`/project/${project.id}`, project);
    }

    addCoworkerToProject(requestData: CoworkerProject): Observable<CoworkerProject> {
        return this.httpService.post('/project/add-coworker', requestData);
    }

    removeCoworkerFromProject(requestData: CoworkerProject) {
        return this.httpService.post('/project/remove-coworker', requestData);
    }

    getProjectById(id: any): Observable<Project> {
        return this.httpService.get('/project/' + id);
    }

    getProjectByName(name: string): any {
        return this.httpService.get('/project/by-name/' + name);
    }

    getProjectsByCoworkerId(id: number, requestParam: boolean): any {
        return this.httpService.get(`/project/user/${id}?active=` + requestParam);
    }

    downloadExportedProject(
        projectFilter: ProjectFilter,
        paidQaReport: boolean,
        paidPmReport: boolean,
        paidDesignReport: boolean,
    ): Observable<HttpResponse<Blob>> {
        const data = {
            paidQaReport: paidQaReport,
            paidPmReport: paidPmReport,
            paidDesignReport: paidDesignReport,
        };
        return this.httpClient.get<Blob>(`${environment.serverUrl}/project/export`, {
            observe: 'response',
            responseType: Blob.name as 'json',
            params: projectFilter.getQueryParams(data),
        });
    }

    saveProjectPic(projectPic: File, id: number) {
        if (projectPic) {
            return this.httpService.postFile('/files/project', projectPic, id).toPromise();
        }
    }

    getProjectPic(id: number) {
        return this.httpClient.get<Blob>(`${environment.serverUrl}/project/image/${id}`, {
            responseType: Blob.name as 'json',
        });
    }

    getTags(id: number) {
        if (id) {
            return this.httpClient.get(`${environment.serverUrl}/project/${id}/tags`);
        }
    }
}

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { phone } from 'phone';

export function hasLengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return (control.value ?? '').trim().length === 0 ? { hasLength: false } : null;
    };
}

/**
 * @description
 * Validator that requires the control have a valid project tag.
 *
 * @usageNotes
 *
 * ### Validate that the field contains a valid project tag
 *
 * @returns An error map with the `containsValidTags` property
 * if the validation check fails, otherwise `null`.
 *
 */
export function ContainsValidTagValidator(tags: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.value?.match(new RegExp(`\\[(${tags.join('|')})-\\d+].*`, 'gi')) ||
            control.value?.match(new RegExp('^[0-9]{1,15} - [A-Za-z0-9]+$', 'gi'))
            ? null
            : { containsValidTags: false };
    };
}

/**
 * @description
 * Validator that disallows the control to have a project tag.
 *
 * @usageNotes
 *
 * ### Validate that the field does not contain a project tag
 *
 * @returns An error map with the `containsTags` property
 * if the validation check fails, otherwise `null`.
 *
 */
export function DoesNotContainTag(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return !control.value?.match(new RegExp(`\\[.+-\\d+].*`, 'g')) ? null : { containsTags: true };
    };
}

export function PhoneNumberValidator(regionCode?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const phoneNumber = phone(control.value, { country: regionCode, validateMobilePrefix: true });

        return phoneNumber.isValid ? null : { validPhoneNumber: false };
    };
}

export function EmailFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.value?.match('[\\w-\\.\\+]+@([\\w-]+\\.)+[\\w-]{2,4}$') ? null : { emailFormat: false };
    };
}

export function SmallerThanOrEqual(otherControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const thisValue = control.value;
        const otherValue = control.parent.get(otherControlName).value;

        return thisValue! <= otherValue ? null : { smallerThan: false };
    };
}

export function RemainingHolidayValidator(otherControlName): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let check: any = null;
        if (control.value != null || control.value != undefined || control.value! > 0) {
            const thisValue: number = parseInt(control.value);
            const otherValue: number = parseInt(control.parent.get(otherControlName).value);
            if (thisValue > otherValue) {
                check = { smallerThan: false };
            }
        } else {
            check = { remainingTest: false };
        }
        return check;
    };
}

export function NotDefaultValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.value != null || control.value != undefined ? null : { notDefaultValue: false };
    };
}

export function HolidaysRangeValidator(type: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const holidayValue = control.value;

        if (holidayValue === null || holidayValue === undefined || isNaN(holidayValue)) {
            return { required: true };
        }

        if (type === 'total' && (holidayValue < 1 || holidayValue > 50)) {
            return { holidayRangeError: true };
        } else if (type === 'remaining' && (holidayValue < 0 || holidayValue > 50)) {
            return { holidayRangeError: true };
        }

        return null;
    };
}

export function RequiredIf(otherField: string, requiredValue: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const otherValue = control.get(otherField)?.value;

        return otherValue === requiredValue && !control.value ? { RequiredIf: true } : null;
    };
}

export function getFirstErrorKey(control: AbstractControl) {
    const errors = control.errors;
    let result: any = null;

    if (errors) {
        const keys = Object.keys(errors);
        if (keys.length > 0) {
            result = keys[0];
        }
    }

    return result;
}

export function isValidationFailed(control: AbstractControl) {
    return control.invalid && (control.dirty || control.touched);
}

export function RequiredBasedOnFormControl(
    controlName: string,
    matchingControlName: string,
    expectedValue: any,
    additionalConditionControlName: string,
): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: any } | null => {
        const control = formGroup.get(controlName);
        const matchingControl = formGroup.get(matchingControlName);
        const additionalConditionControl = formGroup.get(additionalConditionControlName);

        if (!control || !matchingControl || !additionalConditionControl) {
            return null;
        }

        if (control.value === expectedValue && !matchingControl.value && additionalConditionControl.value) {
            matchingControl.setErrors({ required: true });
            return { RequiredBasedOnFormControl: true };
        } else {
            matchingControl.setErrors(null);
        }

        return null;
    };
}

export function isProjectNameValid(projectName: string) {
    const regex = new RegExp("^[\\p{L}.\\-\\s'\\d]+$");
    return regex.test(projectName);
}

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { WorklogUpdateRequest } from '@app/model/worklog-update-request.model';
import WorklogData from '@model/worklog-data';
import { environment } from '../../environments/environment';
import { WorkingTimesResponse } from '../model/working-times.model';
import { WorkingTimesByProjects } from '../model/working-times-by-projects.model';
import { WorkingTimes } from '../model/working-times.model';
import { WorklogRequest } from '../model/worklog-request.model';
import { WorklogTimesheet } from '../model/worklog-timesheet.model';
import { Worklog } from '../model/worklog.model';
import { HttpService } from './http.service';
import { TokenStorageService } from './token-storage.service';
import { WorklogTask } from '@app/model/worklog-task.model';

// TODO refactor method paths to follow rest naming conversion
@Injectable({
    providedIn: 'root',
})
export class WorklogService {
    readonly worklogsPath = '/worklogs';
    private selectedDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

    worklogs: Worklog[];
    worklogChange = new EventEmitter<void>();

    constructor(
        private http: HttpClient,
        // tslint:disable-next-line:deprecation
        private httpService: HttpService,
        private tokenStorage: TokenStorageService,
        private router: Router,
    ) {}

    setSelectedDate(date: Date) {
        this.selectedDate.next(date);
    }

    getSelectedDate$(): Observable<Date> {
        return this.selectedDate.asObservable();
    }

    getTimesheets() {
        return this.httpService.get(this.worklogsPath + '/worklogs');
    }

    getWorklogByMonthAndUser(dateFilter: string): Observable<Worklog[]> {
        return this.httpService.get(
            `${this.worklogsPath}/timesheet/?yearMonth=${dateFilter}&coworkerId=${this.tokenStorage.getUserId()}`,
        );
    }

    getWorklogByToday(): Observable<Worklog[]> {
        return this.httpService.get(
            `${this.worklogsPath}/get?isToday=true&coworkerId=${this.tokenStorage.getUserId()}`,
        );
    }

    getWorklogByYesterday(): Observable<Worklog[]> {
        return this.httpService.get(
            `${this.worklogsPath}/get?isToday=false&coworkerId=${this.tokenStorage.getUserId()}`,
        );
    }

    getWorklogByDay(isToday: boolean): Observable<Worklog[]> {
        return this.httpService.get(
            `${this.worklogsPath}/get?isToday=${isToday}&coworkerId=${this.tokenStorage.getUserId()}`,
        );
    }

    saveWorklog(worklog: WorklogRequest | WorklogRequest[]) {
        const payload = Array.isArray(worklog) ? worklog : [worklog];

        return this.httpService.post(this.worklogsPath, payload);
    }

    updateWorklog(worklogId: number, worklog: WorklogUpdateRequest) {
        return this.httpService.patch(this.worklogsPath + '/' + worklogId, worklog);
    }

    deleteWorklog(worklogId: number) {
        return this.httpService.delete(`/worklogs/${worklogId}`);
    }

    getWorkingTimes(): Observable<WorkingTimesResponse> {
        return this.httpService.get(`${this.worklogsPath}/workingtimes/` + this.tokenStorage.getUserId());
    }

    getWorkingTimesByProjects(isWeekly: boolean): Observable<WorkingTimesByProjects[]> {
        return this.httpService.get(
            `${this.worklogsPath}/projecttimes/?isWeekly=${isWeekly}&coworkerId=${this.tokenStorage.getUserId()}`,
        );
    }

    getWorklogsByCoworker(id: number): Observable<WorklogTimesheet[]> {
        return this.httpService.get(`${this.worklogsPath}/worklogs/` + id);
    }

    getWorklogsByCoworkerBetweenDates(
        id: number,
        startDateTimestamp: number,
        endDateTimestamp: number,
    ): Observable<WorklogTimesheet[]> {
        return this.httpService.get(`${this.worklogsPath}/${id}/${startDateTimestamp}/${endDateTimestamp}`);
    }

    downloadExportedWorklogs(userId: number, projectId: number): Observable<HttpResponse<Blob>> {
        return this.http.get<Blob>(
            `${environment.serverUrl}${this.worklogsPath}/export?userId=${userId}&projectId=${projectId}`,
            {
                observe: 'response',
                responseType: Blob.name as 'json',
            },
        );
    }

    getAllWorklogData(coworkerId: number, projectId: number, from: string, to: string): Observable<WorklogData[]> {
        const params = new HttpParams()
            .set('coworkerId', String(coworkerId))
            .set('projectId', String(projectId))
            .set('from', from)
            .set('to', to);
        return this.http.get<WorklogData[]>(`${environment.serverUrl}${this.worklogsPath}/data`, { params });
    }

    getLatestWorklogData(coworkerId: number, projectId: number): Observable<WorklogData> {
        const params = new HttpParams().set('coworkerId', String(coworkerId)).set('projectId', String(projectId));
        return this.http.get<WorklogData>(`${environment.serverUrl}${this.worklogsPath}/latest-worklog-by-project`, {
            params,
        });
    }

    getWorklogByDate(date: string): Observable<Worklog[]> {
        const params = new HttpParams().set('coworkerId', this.tokenStorage.getUserId()).set('pickedDate', date);
        return this.http.get<Worklog[]>(`${environment.serverUrl}${this.worklogsPath}/date`, { params });
    }

    getWorklogsByDateRange(start: string, end: string): Observable<Worklog[]> {
        return this.http.get<Worklog[]>(
            `${environment.serverUrl}${this.worklogsPath}/get-by-date-range?start=${start}&end=${end}`,
        );
    }

    searchSimilarTaskNames(searchString: string, projectId?: number): Observable<WorklogTask[]> {
        let params = new HttpParams().set('searchString', searchString);

        if (projectId) {
            params = params.set('projectId', projectId);
        }

        return this.http.get<WorklogTask[]>(`${environment.serverUrl}${this.worklogsPath}/search/task`, { params });
    }

    searchSimilarDescriptions(searchString: string, projectId?: number): Observable<string[]> {
        let params = new HttpParams().set('searchString', searchString);

        if (projectId) {
            params = params.set('projectId', projectId);
        }

        return this.http.get<string[]>(`${environment.serverUrl}${this.worklogsPath}/search/description`, { params });
    }

    updateTask(worklogId: number, task: string) {
        let params = new HttpParams().set('task', task);

        return this.http.post(`${environment.serverUrl}${this.worklogsPath}/${worklogId}/updateTask`, params);
    }
}

import { ShiftTime } from './shift-time.model';
import { ShiftState } from '@app/enum/shift-state.enum';

export class ShiftChangeRequest {
    public id: number;
    public userId: number;
    public description: string;
    public date: Date;
    public startDate: Date;
    public endDate: Date;
    public shiftTimes: ShiftTime[];
    public state: ShiftState;
    public decisionMessage: string;
}
